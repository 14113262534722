import type { SystemStyleObject } from "@ui/utils/theme-tools";
const httpErrorContainer: SystemStyleObject = {
    width: "100%",
    minHeight: "201px",
    background: "white",
    paddingTop: [8, 8],
    paddingLeft: [4, 4, 6],
    color: "gray.500",
    fontWeight: "semibold",
    fontSize: "3.5rem",
    lineHeight: "120%",
};

const sectionErrorContainer: SystemStyleObject = {
    width: "100%",
    background: "white",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    bg: "red.50",
    color: "red.200",
    fontWeight: "800",
    fontSize: "1rem",
    lineHeight: "1rem",
    borderColor: "red.200",
    borderWidth: "2px",
};

const errorCode = {
    color: "black",
};

export const styles = {
    httpErrorContainer,
    sectionErrorContainer,
    errorCode,
};

export default styles;
