import { H } from "@ui/components/content/heading/A11yHeading";
import { Box } from "@ui/components/layout/box/Box";
import { Text } from "@ui/components/content/text/Text";

import { styles } from "./error.styles";

type HttpErrorMessageType = {
    errorCode: number;
    headline: string;
    text: string;
    linkToHomeText?: string;
};

export const HttpErrorMessage = ({
    errorCode,
    headline,
    text,
    linkToHomeText,
}: HttpErrorMessageType) => {
    return (
        <Box sx={styles.httpErrorContainer} data-testid="404Container">
            <H size="h1" aria-label={`${headline} ${text} ${errorCode}`}>
                {headline}
            </H>
            <Text
                aria-hidden="true"
                color={"black"}
                fontWeight={"bold"}
                fontSize={["mobileH1", "mobileH1", "tabletH1", "desktopH1"]}
            >
                {text}
            </Text>
            <Text
                aria-hidden="true"
                color={"black"}
                fontWeight={"bold"}
                fontSize={["mobileH1", "mobileH1", "tabletH1", "desktopH1"]}
            >
                {errorCode}
            </Text>
            {linkToHomeText && (
                <p>
                    <a href="/">{linkToHomeText}</a>
                </p>
            )}
        </Box>
    );
};
export default HttpErrorMessage;
